



















import { ProfileModel } from '@/models/profile-model'
import { Observer } from 'mobx-vue'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'user-item': () => import('@/modules/community/home/components/user-item.vue'),
  },
})
export default class UserList extends Vue {
  @Prop() users!: ProfileModel[]

  @Ref('nextButton') nextButton!: any
  @Ref('prevButton') prevButton!: any

  next() {
    this.nextButton.$el.click()
  }

  prev() {
    this.prevButton.$el.click()
  }
}
